<template>
  <div class="our-links-v2">
    <div class="container">
      <div class="our-links-v2-content">
        <div class="our-links-title">
          <div
            v-if="props.values.title_icon"
            class="--icon"
          >
            <nuxt-img
              :src="String(props.values.title_icon)"
              width="58px"
              height="58px"
              fit="cover"
              loading="lazy"
            />
          </div>
          <h2 :style="{color: props.values.color}">{{ props.values.title }}</h2>
        </div>
        <div class="our-links-v2__content">
          <a
            v-for="item in props.values.items"
            :key="item.id"
            :href="item.link"
            target="_blank"
            class="our-links-v2__item"
          >
            <div class="our-links-v2__item-text">
              <span class="item-title">{{ item.title }}</span>
              <div class="item-link" :style="{color: props.values.color}"
              >{{ item.caption }}</div>
            </div>
            <img src="/assets/img/svg/common/arrow.svg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { IBlockDefaultProps } from '#sitis/internal/models/common';

const props = defineProps<IBlockDefaultProps>();
</script>

<style lang="scss" scoped>
.our-links-v2 {
  margin: 56px 0;
  box-sizing: border-box;
}
.our-links-v2-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.our-links-title {
  display: flex;
  align-items: center;
  gap: 16px;

  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 1;

    @media (max-width: 1024px) {
      font-size: 28px;
    }
  }

  .--icon {
    width: 58px;
    height: 58px;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: 0px 2px 5px 0px hsla(var(--black)/0.25), 0px 9px 9px 0px hsla(var(--black)/0.21), 0px 20px 12px 0px hsla(var(--black)/0.13), 0px 35px 14px 0px hsla(var(--black)/0.04), 0px 55px 15px 0px hsla(var(--black)/0), 0px 4px 4px 0px hsla(var(--white)/0.25) inset;
  }
  .--icon img {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
  }
}
.our-links-v2__content {
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
  margin-left: -12px;

  @media(max-width: 1024px) {
    flex-direction: column;
    margin-top: -6px;
    margin-left: -6px;
  }
}
.our-links-v2__item-text {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: hsla(var(--black)/1);
  }

  .item-link {
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
  }
}
.our-links-v2__item {
  display: flex;
  justify-content: space-between;
  padding: 18px 22px 17px 20px;
  margin-top: 11px;
  margin-left: 11px;
  background-color: hsla(var(--white)/1);
  border-radius: 25px;
  border: 1px solid hsla(var(--black)/0.1);
  box-shadow: 0px 8px 16px 0px #0000000D;

  img {
    transform: rotate(180deg);
    filter: var(--primary-filter-icon);
  }
  &:hover {
    background: hsla(var(--primary-bg)/0.2);
    color: hsla(var(--primary-tx)/1);
  }
  @media(max-width: 1024px) {
    margin-top: 6px;
    margin-left: 6px;
  }
  @media(min-width: 1025px) {
    width: calc(50% - 11px);
  }
}
</style>
